<script setup lang="ts">
import {
  LOCALE_CODES,
} from '@yescapa-dev/ysc-components/i18n'
import { DRAWING_LOW_PROFILE_BIKES, FRIENDS, USERS_AND_VAN } from '~/constants/pictures'
import { YSC_API_ERROR, YSC_API_REVIEW_ERROR, YSC_ERROR } from '~/utils/error/YscErrorClasses'

const { d, locale } = useI18n()
const { $api } = useYscApi()
const { $errorManager } = useErrorManager()

const { stats } = storeToRefs(useStatsStore())
const { localeProperties: { yscCode } } = useLocaleProperties()

const { data, error } = await useAsyncData('page-index', async () => {
  let reviews = null, cities = null, countries = null, poi = null, offers = null
  const [fetchReviews, fetchCities, fetchCountries, fetchPoi, fetchOffers] = await Promise.allSettled([
    $api.reviews.getAll({
      page_size: 9,
      has_photos: true,
      allowed_on_home: true,
      language: getTopLevelLocale(yscCode),
    }),
    $api.geolocation.getPlaces({ place_type: 'CITY', allowed_on_home: true }),
    $api.geolocation.getPlaces({ place_type: 'COUNTRY', allowed_on_home: true }),
    $fetch('/api/internals/poi', { query: { lang: yscCode } }),
    $fetch('/api/internals/offers', { query: { lang: yscCode } }),
  ])

  if (fetchReviews.status === 'rejected') {
    $errorManager({ e: fetchReviews.reason, name: YSC_API_REVIEW_ERROR })
  }
  else {
    reviews = fetchReviews.value.results.map(({ author: { first_name }, published_date, photos, id }) => ({
      id,
      image: photos[0].path,
      first_name,
      published_date: published_date ? d(new Date(published_date), 'Y.numeric M.long') : null,
    }))
  }

  if (fetchCities.status === 'rejected') {
    $errorManager({ e: fetchCities.reason, name: YSC_API_ERROR })
  }
  else {
    cities = getPlaceLinks(fetchCities.value.results)
  }

  if (fetchCountries.status === 'rejected') {
    $errorManager({ e: fetchCountries.reason, name: YSC_API_ERROR })
  }
  else {
    countries = fetchCountries.value.results
  }

  if (fetchPoi.status === 'rejected') {
    $errorManager({ e: fetchPoi.reason, name: YSC_ERROR })
  }
  else {
    poi = fetchPoi.value.results
  }

  if (fetchOffers.status === 'rejected') {
    $errorManager({ e: fetchOffers.reason, name: YSC_ERROR })
  }
  else {
    offers = fetchOffers.value.results
  }

  return { reviews, cities, countries, poi, offers }
})

if (error.value) {
  throw createError(error.value)
}

const { vehicleTypes } = useVehicleTypeDrawing({
  bgColors: ['bg-pink-100', 'bg-peacock-100', 'bg-yellow-100'],
  filter: item => [
    LandingCamperTypes.VAN,
    LandingCamperTypes.CAMPERVAN,
    LandingCamperTypes.LOWPROFILE,
    LandingCamperTypes.COACHBUILT,
    LandingCamperTypes.ACLASS,
  ].includes(item.slug),
})

const { getTranslatedHomepageOffers } = useGetTranslatedHomepageOffers()

const { routing } = storeToRefs(useRoutingStore())
const { t } = useI18n()

const appLandingCountriesAddionalLinks = [
  { key: 'commons.camper_children', name: t('commons.camper_children'), url: { name: 'rv-hire-family' } },
  // @ts-expect-error mssing type in routing
  { key: 'commons.camper_couple', name: t('commons.camper_couple'), url: routing.value?.landings.couple },
  { key: 'commons.camper_animals', name: t('commons.camper_animals'), url: { name: 'rv-hire-with-pets' } },
  { key: 'commons.camper_surftrip', name: t('commons.camper_surftrip'), url: { name: 'surftrip' } },
  { key: 'commons.camper_marriage', name: t('commons.camper_marriage'), url: routing.value?.landings.wedding },
  { key: 'commons.camper_volkswagen', name: t('commons.camper_volkswagen'), url: { name: 'we-love-california' } },
]
</script>

<template>
  <div>
    <AppPageHero
      :bg-image="twicpicsPath(USERS_AND_VAN, { isLibrary: true })"
      :bg-image-alt="$t('pages.index.title_seo')"
      with-stamp
      with-drawing
    >
      <template #title-big>
        {{ $t('pages.index.title') }}
      </template>
      <template #title-seo>
        {{ $t('pages.index.title_seo') }}
      </template>
    </AppPageHero>

    <AppPageHeroBanner
      :average="stats.average"
      :count="stats.count"
    />

    <div v-if="locale === LOCALE_CODES.DE">
      <div class="bg-gray-50 lg:hidden">
        <div class="container py-6">
          <img
            src="~assets/img/awards/DKA2023_large.png"
            class="block w-60 mx-auto"
            alt="-"
            loading="lazy"
          >
        </div>
      </div>
    </div>

    <div
      v-if="data?.offers"
      class="container pt-8 md:pt-16"
    >
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-3 md:flex-row lg:gap-12 justify-items-center">
        <AppLandingOffer :link="data.offers.link">
          <template #picture>
            <div
              class="relative overflow-hidden rounded-l-md sm:rounded-3xl sm:rounded-b-none sm:aspect-video app-landing-offer-img-container before:md:absolute before:md:inset-0 before:md:bg-white h-full"
            >
              <YscPicture
                :path="twicpicsPath(data.offers.image_path, { isLibrary: true })"
                :sources="[
                  { breakpoint: 'xl', width: 437, height: 244, refitParameter: '@bottom-right' },
                  { breakpoint: 'lg', width: 380, height: 213, refitParameter: '@bottom-right' },
                  { breakpoint: 'md', width: 315, height: 177, refitParameter: '@bottom-right' },
                  { breakpoint: 'sm', width: 293, height: 165, refitParameter: '@bottom-right' },
                ]"
                with-cover-transformation
                with-refit-transformation
                :default-source="{ width: 64, height: 64, refitParameter: '@bottom-right' }"
                fetchpriority="high"
                loading="eager"
                :alt="$t(data.offers.title)"
              />
            </div>
          </template>
          <template #title>
            {{ $t(data.offers.title) }}
          </template>
          <template #description>
            <i18n-t
              :keypath="data.offers.description"
              tag="p"
              class="text-xs lg:text-sm"
              scope="global"
            >
              <template
                v-for="emphasis in getTranslatedHomepageOffers(data.offers.type)"
                #[emphasis.key]
                :key="emphasis.key"
              >
                <strong class="bg-orange-200">{{ emphasis.value }}</strong>
              </template>
            </i18n-t>
            <button class="btn btn-secondary hidden sm:block mt-4 text-sm">
              {{ $t('pages.index.all_good_deals') }}
              <YscIconsArrowRight class="h-5 w-5 inline" />
            </button>
          </template>
        </AppLandingOffer>
        <AppLandingOffer
          class="hidden lg:block"
          :link="{ name: 'community-all-reviews' }"
        >
          <template #picture>
            <div
              class="relative overflow-hidden rounded-l-md sm:rounded-3xl sm:rounded-b-none sm:aspect-video app-landing-offer-img-container before:md:absolute before:md:inset-0 before:md:bg-white h-full"
            >
              <YscPicture
                :path="twicpicsPath(FRIENDS, { isLibrary: true })"
                :sources="[
                  { breakpoint: 'xl', width: 437, height: 244 },
                  { breakpoint: 'lg', width: 293, height: 165 },
                ]"
                with-cover-transformation
                :default-source="{ width: 64, height: 64 }"
                fetchpriority="high"
                loading="eager"
                :alt="$t('pages.index.a_rolling_story')"
              />
            </div>
          </template>
          <template #title>
            {{ $t('pages.index.a_rolling_story') }}
          </template>
          <template #description>
            <span class="flex items-center">
              <YscIconsStarSolid
                v-for="index in 5"
                :key="index"
                class="h-5 w-5 shrink-0 text-yellow-400"
              />
              <span class="text-2xl ml-2"><strong>{{ $n(stats.average) }}/5</strong></span>
            </span>
            <p class="text-xs lg:text-sm">
              {{ $t('pages.index.subtitle_reviews') }}
            </p>
            <p class="text-xs lg:text-sm">
              {{ $t('pages.index.rating_ysc_dynamic', { note_string: $n(stats.average), total_string: $n(stats.count) }) }}
            </p>
          </template>
        </AppLandingOffer>
        <AppLandingOffer
          :link="{ name: 'rent-my-motorhome' }"
          class="bg-peacock-700 text-white"
        >
          <template #picture>
            <div
              class="relative overflow-hidden rounded-l-md sm:rounded-3xl sm:rounded-b-none sm:aspect-video h-full"
            >
              <YscPicture
                class="-ml-12 md:-ml-28 overflow-visible"
                :path="twicpicsPath(DRAWING_LOW_PROFILE_BIKES, { isLibrary: true })"
                :sources="[
                  { breakpoint: 'xl', width: 600, height: 280, refitParameter: '@right' },
                  { breakpoint: 'lg', width: 474, height: 230, refitParameter: '@right' },
                  { breakpoint: 'md', width: 330, height: 160, refitParameter: '@right' },
                ]"
                :default-source="{ width: 84, height: 50, refitParameter: '@right' }"
                fetchpriority="high"
                loading="eager"
                :alt="$t('pages.index.push_owner_title')"
              />
            </div>
          </template>
          <template #title>
            {{ $t('pages.index.push_owner_title') }}
          </template>
          <template #description>
            <p class="text-xs lg:text-sm">
              {{ $t('pages.index.push_owner_description') }}
            </p>
            <button class="btn btn-primary hidden sm:block mt-4 text-sm">
              {{ $t('commons.actions.know_more') }}
              <YscIconsArrowRight class="h-5 w-5 inline" />
            </button>
          </template>
        </AppLandingOffer>
      </div>
    </div>

    <AppSection>
      <template #title-section>
        {{ $t('pages.index.choose_right_vehicle') }}
      </template>
      <template #title>
        {{ $t('pages.index.which_camper_type') }}
      </template>
      <template #subtitle-aside>
        {{ $t('pages.index.roadtrip_key') }}
      </template>
      <AppCamperTypesScroller
        :vehicle-types="vehicleTypes"
        scroll-indicator-color="from-white via-white"
      />
    </AppSection>

    <AppSection class="bg-gray-50">
      <template #title-section>
        {{ $t('pages.index.our_destinations') }}
      </template>
      <template #title>
        {{ $t('pages.index.everything_is_possible') }}
      </template>
      <template #subtitle>
        {{ $t('pages.index.choice_is_yours') }}
      </template>

      <AppContentScroller
        v-if="data?.poi?.length"
        class="space-x-6 pb-6 lg:grid lg:grid-cols-3 lg:grid-rows-4 lg:gap-4 lg:space-x-0 xl:grid-cols-4 xl:grid-rows-3 xl:gap-6"
      >
        <AppDestinationCard
          v-for="(p, i) in data.poi"
          :key="p.title"
          :src-image="p.image"
          :link="p.link"
          class="shrink-0"
          :class="{ 'row-span-2': [2, 4, 6].includes(i), 'col-span-2': i === 4, 'row-start-2 xl:col-start-4': i === 6 }"
        >
          <template #title>
            {{ p.title }}
          </template>

          <template #place>
            {{ p.place }}
          </template>
        </AppDestinationCard>
      </AppContentScroller>

      <AppContentScroller
        v-if="data?.cities"
        class="mt-6 !block space-x-3.5 whitespace-nowrap text-center"
      >
        <NuxtLink
          v-for="city in data.cities"
          :key="city.name"
          :to="city.url"
          class="btn btn-tertiary btn-small bg-white shrink-0"
        >
          {{ city.name }}
        </NuxtLink>
      </AppContentScroller>

      <div class="mt-12 text-center">
        <NuxtLink
          :to="{ name: 's' }"
          class="btn btn-primary"
          no-prefetch
        >
          {{ $t('commons.links.search') }}
          <YscIconsArrowRight class="ml-2 h-5 w-5 shrink-0" />
        </NuxtLink>
      </div>
    </AppSection>

    <div class="container py-8 md:py-12 xl:flex xl:flex-row-reverse xl:items-center xl:gap-20">
      <div class="xl:basis-1/2">
        <header>
          <h3
            class="relative mb-4 pb-4 font-semibold text-pink-500 after:absolute after:bottom-0 after:block after:h-1 after:w-20 after:bg-pink-500 after:content-[''] lg:mb-8"
          >
            {{ $t('pages.index.meet_the_community') }}
          </h3>
          <div class="mb-8 lg:flex">
            <h2 class="whitespace-pre-line text-3xl font-semibold lg:text-5xl">
              {{ $t('pages.index.a_rolling_story') }}
            </h2>
          </div>
        </header>
        <div class="flex">
          <YscIconsStarSolid
            v-for="i in 5"
            :key="`star-${i}`"
            class="w-8 text-yellow-400"
          />
        </div>
        <i18n-t
          keypath="pages.index.rating_ysc_details_dynamic"
          tag="p"
          class="mt-4 whitespace-pre-line text-xl font-semibold"
          scope="global"
        >
          <template #note_string>
            {{ $n(stats.average) }}
          </template>
          <template #total_string>
            {{ $n(stats.count) }}
          </template>
        </i18n-t>
        <div class="max-w-prose">
          <p class="mt-4">
            {{ $t('pages.index.every_story_is_unique') }}
          </p>
        </div>
        <div class="mt-12 flex flex-col items-start gap-4">
          <NuxtLink
            class="btn btn-secondary"
            :to="{ name: 'community-all-reviews' }"
          >
            {{ $t('pages.index.read_yescapa_reviews') }}
          </NuxtLink>
          <NuxtLink
            class="btn btn-tertiary"
            :to="{ name: 'community-pictures-wall' }"
          >
            {{ $t('pages.index.see_yescapa_pictures_reviews') }}
          </NuxtLink>
        </div>
      </div>

      <AppContentScroller
        class="relative mt-12 justify-between space-x-6 xl:mt-0 xl:block xl:basis-1/2 xl:columns-3 xl:gap-6 xl:space-x-0 xl:before:absolute xl:before:left-0 xl:before:top-0 xl:before:z-10 xl:before:h-32 xl:before:w-full xl:before:bg-gradient-to-b xl:before:from-white xl:before:to-transparent xl:before:content-[''] xl:after:absolute xl:after:bottom-0 xl:after:left-0 xl:after:z-10 xl:after:h-32 xl:after:w-full xl:after:bg-gradient-to-t xl:after:from-white xl:after:to-transparent xl:after:content-['']"
      >
        <article
          v-for="(review, i) in data?.reviews"
          :key="review.id"
          class="relative flex h-52 w-44 shrink-0 flex-col justify-end overflow-hidden rounded xl:mt-6 xl:w-auto xl:first:mt-0"
          :class="{ 'xl:top-14 xl:mb-20': i % 6 === 0 }"
        >
          <div class="z-10 p-3 text-xs leading-4 text-white">
            <p class="font-semibold">
              {{ review.first_name }}
            </p>
            <p>
              {{ review.published_date }}
            </p>
          </div>
          <twic-img
            :src="twicpicsPath(review.image)"
            :alt="`${review.first_name} ${review.published_date} - Yescapa`"
            ratio="none"
            class="absolute inset-0 z-0 after:absolute after:inset-0 after:bg-gradient-to-t after:from-black/90 after:to-transparent after:bg-[length:100%_70%] after:bg-bottom after:bg-no-repeat after:content-['']"
            placeholder="none"
            bot="cover=800x800"
          />
        </article>
      </AppContentScroller>
    </div>

    <AppLandingOwnerCreateCamper>
      <template #title>
        {{ $t('pages.index.complete_confidence') }}
      </template>
    </AppLandingOwnerCreateCamper>

    <AppLandingCountries
      v-if="data?.countries"
      :countries="data.countries"
      :additional-links="appLandingCountriesAddionalLinks"
    />

    <div class="bg-gray-100">
      <AppNewsletterBlog class="container overflow-hidden py-14" />
    </div>
  </div>
</template>

<style lang="css">
.force-object-fit-right img{
  @apply object-right;
}
</style>
